import axios, { AxiosInstance } from "axios";
import type {
  AddToCartItem,
  AddToCartPayload,
  Cart,
  ChangeCartItemPayload,
  UpdateCartPayload,
} from "./types";

const { routes } = useTheme();

export class CartAPI {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async fetchCart(): Promise<Cart> {
    const { data } = await this.client.get<Cart>("/cart.js");
    return data;
  }

  async addItemToCart(
    payload: AddToCartPayload
  ): Promise<{ items: AddToCartItem[] }> {
    const { data } = await this.client.post<{ items: AddToCartItem[] }>(
      routes.cart_add_url,
      payload
    );
    return data;
  }

  async updateCart(payload: UpdateCartPayload): Promise<Cart> {
    const { data } = await this.client.post<Cart>(
      routes.cart_update_url,
      payload
    );
    return data;
  }

  async changeCartItem(payload: ChangeCartItemPayload): Promise<Cart> {
    const { data } = await this.client.post<Cart>(
      routes.cart_change_url,
      payload
    );
    return data;
  }

  async clearCart(): Promise<Cart> {
    const { data } = await this.client.post<Cart>(routes.cart_clear_url);
    return data;
  }
}
