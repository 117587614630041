import { useAsyncState } from "@vueuse/core";
import { computed, ref } from "vue";
import { CartAPI } from "./api";
import type {
  AddToCartPayload,
  Cart,
  ChangeCartItemPayload,
  UpdateCartPayload,
} from "./types";

const { formatMoney } = useMoney();

const api = new CartAPI();
const loading = ref(false);
const initialCartState: Cart | undefined = undefined;

const cart = useAsyncState<Cart | undefined>(
  () => api.fetchCart(),
  initialCartState,
  {
    immediate: true,
    resetOnExecute: false,
  }
);

const isLoading = computed(() => cart.isLoading.value || loading.value);
const total = computed(() => formatMoney(cart.state.value?.total_price ?? 0));

export function useCart() {
  async function addItemToCart(payload: AddToCartPayload) {
    try {
      loading.value = true;
      const data = await api.addItemToCart(payload);
      await cart.execute();
      return data;
    } catch (error) {
      console.error("Failed to add item to cart:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  }

  async function updateCart(payload: UpdateCartPayload) {
    try {
      loading.value = true;
      const data = await api.updateCart(payload);
      await cart.execute();
      return data;
    } catch (error) {
      console.error("Failed to update cart:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  }

  async function changeCartItem(payload: ChangeCartItemPayload) {
    try {
      loading.value = true;
      const data = await api.changeCartItem(payload);
      await cart.execute();
      return data;
    } catch (error) {
      console.error("Failed to change cart item:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  }

  async function changeCartItemQuantity(
    variantId: number | string,
    quantity: number
  ) {
    return updateCart({ updates: { [variantId]: quantity } });
  }

  async function removeCartItem(variantId: number | string) {
    return changeCartItemQuantity(variantId, 0);
  }

  async function clearCart() {
    try {
      loading.value = true;
      const clearedCart = await api.clearCart();
      const attributes = Object.fromEntries(
        Object.keys(clearedCart.attributes).map((key) => [key, ""])
      );
      return await updateCart({ attributes });
    } catch (error) {
      console.error("Failed to clear cart:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  }

  return {
    ...cart,
    total,
    isLoading,
    removeCartItem,
    changeCartItemQuantity,
    changeCartItem,
    updateCart,
    addItemToCart,
    clearCart,
  };
}

export default useCart;
